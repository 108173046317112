import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import TextTransition, { presets } from "react-text-transition";
//import Typing from "react-typing-animation";
import "./App.css";
import { useInterval } from "./utils";

const slideshowArr = ["comic fans", "movie fans", "TV fans", "book worms"];

function App() {
  const [index, setIndex] = useState(
    Math.round(Math.random() * (slideshowArr.length - 1))
  );

  useInterval(() => {
    setIndex((index + 1) % slideshowArr.length);
  }, 3000);

  return (
    <>
      {/* For desktop devices */}
      <Container className="d-none d-sm-block maxHeight">
        <Row className="maxHeight">
          <Col xs="0" sm="0" md="2" xl="2" className="d-none d-sm-flex"></Col>
          <Col xs="0" sm="12" md="4" xl="4" className="d-none d-sm-flex">
            <Row className="row maxHeight justify-content align-items-center">
              <video
                width="100%"
                poster="https://firebasestorage.googleapis.com/v0/b/skinster-app.appspot.com/o/Website%2FStatic%2FPlaceholder-Compressed.png?alt=media&token=5c1eab61-5e0a-4f27-9b31-b567706df43e"
                loop
                autoPlay
                muted
                className="centerForce"
              >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/skinster-app.appspot.com/o/Website%2FStatic%2FSkinsterDemoVideoWOBadge-Compress%40h1000.m4v?alt=media&token=c83f5af9-c109-49bf-9867-5c4b3f5c9506"
                  type="video/mp4"
                />
              </video>
            </Row>
          </Col>
          <Col xs="12" sm="12" md="4" xl="4" className="d-none d-sm-flex">
            <Row className="Padded row maxHeight justify-content-sm-center justify-content-md-start align-items-sm-start align-items-md-center">
              <div>
                <h1 className="AppTitle ConditionalCenteredText">Skinster</h1>
                <h3 className="ConditionalCenteredText">
                  Chat with{" "}
                  <div className="AudienceSlideShowContainer">
                    <TextTransition
                      text={"  " + slideshowArr[index]}
                      springConfig={presets.slow}
                      className="centerForce"
                    />
                  </div>{" "}
                  around the world in skins of your favorite characters.
                </h3>
                <p className="ConditionalCenteredText">
                  Brings your favorite characters to life.
                </p>
                <div className="ConditionalCenteredView">
                  {/*<img
                    src="badge-pre-order-on-the-app-store.svg"
                    className="AppStoreBadge AdjustedSize"
                    alt=""
                  />*/}
                  <a href="https://skinster.page.link/pre-register-android">
                    <img
                      width="160px"
                      src="play_store_web_generic_wo_padding.png"
                      className="AppStoreBadge"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </Row>
          </Col>
          <Col xs="0" sm="0" md="2" xl="2" className="d-None d-sm-flex"></Col>
        </Row>
      </Container>

      {/* For mobile devices */}
      <Container className="d-block d-sm-none maxHeight">
        <Row className="h-75">
          <Col className="h-100">
            <Row className="row h-100 justify-content align-items-center">
              <video
                poster="https://firebasestorage.googleapis.com/v0/b/skinster-app.appspot.com/o/Website%2FStatic%2FPlaceholder-Compressed.png?alt=media&token=5c1eab61-5e0a-4f27-9b31-b567706df43e"
                loop
                autoPlay
                muted
                playsInline
                className="centerForce h-100"
              >
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/skinster-app.appspot.com/o/Website%2FStatic%2FSkinsterDemoVideoWOBadge-Compress%40h1000.m4v?alt=media&token=c83f5af9-c109-49bf-9867-5c4b3f5c9506"
                  type="video/mp4"
                />
              </video>
            </Row>
          </Col>
        </Row>
        <Row className="h-25">
          <Col xs="12">
            {/*<Row>
              <p>
                Get closer to bringing your favorite characters to life with one
                click
              </p>
            </Row>*/}
            <Row>
              <p className="TextMobiBanner">
                Chat with fiction fans around the world in skins of your
                favorite characters.
              </p>
            </Row>
            <Row>
              <div className="ConditionalCenteredView">
                {/*<img
                  src="badge-pre-order-on-the-app-store.svg"
                  className="AppStoreBadge AdjustedSize"
                  alt=""
                />*/}
                <a href="https://skinster.page.link/pre-register-android">
                  <img
                    width="160px"
                    src="play_store_web_generic_wo_padding.png"
                    className="AppStoreBadge"
                    alt=""
                  />
                </a>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
